import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

export type ControllerProps = {};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  return <div data-hook="PostHeader-wrapper"></div>;
};

export default Widget;
